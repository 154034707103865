<template>
  <div class="reinvestment">
    <TopBack :title="$t('home.qxft')"></TopBack>
    <ul>
      <li v-for="(item,i) in list" :key="i">
        <div class="right directionColumn">
          <p>NO:{{item.id}}</p>
          <span>{{item.amount}}+{{item.interest}}</span>
        </div>
        <div class="left directionColumn">
          <p :style="item.status!==1?'color: #FF5A5A':''">{{getSubStatus(item.status)}}</p>
          <van-button class="confirm" @click="cancellation(item.id)" v-if="item.status===1">{{$t('home.qc')}}</van-button>
        </div>
      </li>
    </ul>
    <van-popup v-model:show="show"  closeable :close-on-click-overlay="false" @close="close" >
      <div class="password">
        <div class="title">{{$t('home.zfmm')}}</div>
        <van-cell-group inset>
          <van-field v-model="payPwd"  center :placeholder="$t('home.srmm')" maxlength="6"/>
        </van-cell-group>
        <div class="confirm-btn">
          <van-button class="confirm" @click="submit">{{$t('home.qd')}}</van-button>
        </div>
      </div>
    </van-popup>
  </div>

</template>
<script setup>
import TopBack from './components/TopBack'
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {getCouldCancel,setCancelReinvestment} from "../api";
import {useRoute} from "vue-router";
import {getPublicKey} from "../utils/decimalTool";
import {Toast} from "vant";
const { $t } = getCurrentInstance().proxy;
const show=ref(false)
const payPwd=ref('')
const orderItemsId=ref(0)
const cancellation=(id)=>{
  orderItemsId.value=id
  payPwd.value=''
  show.value=true
}
const close=()=>{
  show.value=false
  payPwd.value=''
}
const submit=()=>{
  setCancelReinvestment({
    "gameId":route.query.gameId, //游戏id
    "gameItemId":route.query.id, //游戏轮数id
    'payPwd' :getPublicKey(payPwd.value),//公钥加密后
    orderItemsId:orderItemsId.value
  }).then(res=>{
    if(res.code==='200'){
      // eslint-disable-next-line no-undef
      close()
      Toast($t('home.success'))
      getHttp()
    }else{
      Toast(res.msg)
    }
  })
}
onBeforeMount(()=>{
  getHttp()

})
const route=useRoute()
const list=ref([])
const  getHttp=()=>{
  getCouldCancel({
    "gameId":route.query.gameId,  // 游戏id
    "gameItemId":route.query.id // 游戏轮次id
  }).then(res=>{
    list.value=res.data
  })
}
const getSubStatus=(status)=>{
  let text
  switch (status) {
    case 1 :
      text=$t('home.zcSatuts1')
      break
    case 2 :
      text=$t('home.zcSatuts2')
      break
    case 3 :
      text=$t('home.zcSatuts3')
      break
    case 4 :
      text=$t('home.zcSatuts4')
      break
    case 5 :
      text=$t('home.zcSatuts5')
      break
  }
  return text
}
</script>
<style scoped lang="less">
.directionColumn{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reinvestment{
  box-sizing: border-box;
  min-height: 100vh;
  padding:0 18px 21px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  li{
    display: flex;
    align-items: start;
    justify-content: space-between;
    border-bottom: 1px solid #7E8D95;
    height: 77px;
    .right{
      align-items: start;
      p{
        font-weight: 500;
        font-size: 15px;
        color: #7E8D95;
        margin: 15px 0 5px 0;

      }
      span{
        font-weight: 400;
        font-size: 17px;
        color: #FFFFFF;
        line-height: 35px;
      }
    }
    .left{
      align-items: end;
      height: 100%;
      p{
        font-weight: 500;
        font-size: 16px;
        color: #6FFEE8;
        margin: 0;

      }
      .confirm{
        height: 27px;
        line-height: 27px;
        background: linear-gradient(90deg, #57F6CD, #AF65EE);
        border-radius: 26px;
        font-weight: bold;
        font-size: 15px;
        color: #000000;
        padding: 0 18px;
        border: none;
        margin-top: 6px;
      }
    }

  }

}
/deep/.van-popup {
  background: #232A2E;
  border-radius: 25px;
}
/deep/.van-popup__close-icon--top-right{
  top:30px;
  height: 24px;
}
.password{
  box-sizing: border-box;
  width: 312px;
  //height: 320px;
  background: #232A2E;
  border-radius: 25px;
  padding: 28px 19px 31px 19px;
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .confirm-btn{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    .confirm{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      text-align: center;
      line-height: 56px;
      border: none;
    }
  }
  /deep/ .van-cell-group{
    width: 100%;
    height: 62px;
    background: #000000;
    border-radius: 31px;
  }
  /deep/ .van-cell-group--inset{
    margin: 0;
    .van-cell{
      font-weight: 500;
      font-size: 17px;
      color: #7E8D95;
      width: 264px;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    .van-field__control{
      text-align: center;
      border: none;
      color: #6FFEE8;
    }
  }
}
</style>
